import { borderRadius, colorTokens } from '@orus.eu/pharaoh'

export function requiredFieldMixin(required: boolean | null | undefined, value: unknown): string {
  return required && (value == undefined || value === '') ? requiredMissingFieldCss : ''
}

const requiredMissingFieldCss = `
  position: relative;
  :before {
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    outline: 2px solid ${colorTokens['color-stroke-danger']};
    border-radius: ${borderRadius[20]};
    pointer-events: none;
  }
`
